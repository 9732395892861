<!-- 主页部分 -->
<template>
  <section class="mainLayout">
    <div class="tab-wrap">
      <div
        :class="['tab', currentName === item.routerName ? 'active' : '']"
        v-for="(item, index) in tabList"
        :key="`tab${index}`"
        @click="switchTab(item)"
      >
        <span class="tab-name">{{ item.name }}</span>
        <!-- 概览不可关闭 -->
        <i
          v-if="item.routerName !== 'Overview'"
          class="el-icon-close close"
          @click.stop="closeTab(index, item.routerName)"
        ></i>
      </div>
    </div>
    <div class="wrap">
      <keep-alive exclude="QueryVue">
        <router-view></router-view>
      </keep-alive>
    </div>
  </section>
</template>

<script>
import Overview from "@/views/digitalAssetsOpenNetwork/Overview.vue";
import QueryVue from "@/views/digitalAssetsOpenNetwork/QueryVue.vue";
import Account from "@/views/customerManagement/Account.vue";
import Filing from "@/views/customerManagement/Filing.vue";
import { sortMenus } from "@/util/common";  
import { mapMutations } from "vuex";

export default {
  name: "mainLayout",
  components: {
    Overview,
    QueryVue,
    Filing,
    Account
  },
  data() {
    return {
      currentName: "Overview", //动态组件当前渲染组件名
      tabList: [] //导航tab
    };
  },
  watch: {
    $route: {
      // $route可以用引号，也可以不用引号
      handler(val) {
        const { meta:{tabName} } = val;
        this.currentName = tabName;
        this.tabList = sortMenus(JSON.parse(localStorage.getItem("tabList") || "[]"));
        this.setTabMenuName(this.currentName);
        this.$emit('update-tabList', this.tabList);
      },
      deep: true, // 深度监听
      immediate: true // 第一次初始化渲染就可以监听到
    }
  },
  created() {},
  mounted() {},
  methods: {
    ...mapMutations(["setTabMenuName"]),
    routerPush(name) {
      this.$router.push({ name });
    },
    //关闭tab
    closeTab(index, name) {
      const prevRouterName = this.tabList[index - 1].routerName; //获取active状态tab，前一个tab名
      this.tabList = this.tabList.filter((item => {
        if(item.routerName !== name) {
          return item;
        }
        return null;
      }));
      localStorage.setItem("tabList", JSON.stringify(this.tabList));
       //当关闭正在active状态tab时
      if (name === this.currentName) {
        this.currentName = prevRouterName;
        this.routerPush(prevRouterName); //跳转到前一个tab
      }
      this.setTabMenuName(this.currentName);
      this.$emit('update-tabList', this.tabList);
    },
    //切换tab
    switchTab(item) {
      const { routerName } = item;
      this.currentName = routerName;
      this.setTabMenuName(this.currentName);
      this.$emit('update-tabList', this.tabList);
      this.routerPush(routerName);
    }
  }
};
</script>
<style lang="scss" scoped>
.mainLayout {
  height: 100%;
}
//主页容器
.wrap {
  background: #f6f7fb;
  min-height: calc(100% - 48px);
  padding: 24px;
  overflow: auto;
  section {
    background: #ffffff;
  }
}
//主页tab
.tab-wrap {
  position: relative;
  height: 48px;
  padding-left: 32px;
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 63, 158, 0.03);
}
.tab {
  display: inline-block;
  padding: 4px 8px;
  margin-right: 16px;
  color: #8f96a3;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  line-height: normal;
  cursor: pointer;
  .tab-name {
    cursor: pointer;

    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    vertical-align: middle;
  }
  &.active {
    background: #498aff;
    color: #ffffff;
  }
}
.close {
  cursor: pointer;
  vertical-align: middle;
  margin-left: 2px;
}
</style>
